import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import routes from './router'
import VueIframe from 'vue-iframes'
import { createMetaManager } from 'vue-meta'
import { createStore } from 'vuex'

import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import BackToTop from 'vue-backtotop'
import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/gordita-font.css'
import '../src/assets/css/exo2-font.css'
import '../src/assets/scss/style.scss'

const store = createStore({
    state () {
        return {
            title: 'Riset AI',
            description: '',
        }
    },
    mutations: {
        setState (state, data) {
            state.title = data.title
            state.description = data.description
        }
    }
})

createApp(App).use(routes).use(BackToTop).use(VueAxios, axios).use(createMetaManager()).use(store).use(VueIframe).mount('#app')