<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `Riset AI` }}</template>
    </metainfo>
    <router-view v-slot="{ Component, route }">
        <component :is="Component" :key="route.path" />
    </router-view>
    <!-- <router-view /> -->
</template>
<script>
import { useMeta } from 'vue-meta'
export default {
    setup () {
        useMeta({
            title: '',
            htmlAttrs: { lang: 'en', amp: true }
        })
    }
}
</script>
<style lang="scss">
.back-top {
    background-color: #89715b;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    right: 50px;
    bottom: 50px;
    font-size: 14px;
    z-index: 18;
    border: 50%;
}
</style>
