import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../pages/Home.vue'

const Home = () => import('../pages/Home.vue')
const ProductDetail = () => import('../pages/ProductDetail.vue')
const Product = () => import('../pages/Product.vue')
const BlogDetail = () => import('../pages/BlogDetail.vue')
const Blog = () => import('../pages/Blog.vue')
const About = () => import('../pages/AboutUs.vue')
const Contact = () => import('../pages/Contact.vue')
const Error = () => import('../pages/404.vue')
const Terms = () => import('../pages/TermCondition.vue')
const Privacy = () => import('../pages/PrivacyPolicy.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product-detail/:slug',
    name: 'product-detail',
    component: ProductDetail,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/blog-detail/:slug',
    name: 'blog-detail',
    component: BlogDetail,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/404',
    name: '404',
    component: Error
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Error
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../pages/Register.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../pages/Login.vue')
  // },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: About
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: Terms
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: Privacy
  }
]
const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function (to, from, next) {
  if (!!to.hash === false) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  // window.location.reload()
  next()
})
export default router
